import { buildCountriesToLanguages } from '@bemer/middleware';
import { TLocaleIdentifier, TCurrency } from '@bemer/types';

const translations = {
  fieldset_headline__choose_product: 'Choose set',
  fieldset_headline__organization_data: 'Your organization',
  fieldset_headline__personal_data: 'Personal data',
  fieldset_headline__address_data: 'Address data',
  fieldset_headline__deliveryAddress_data: 'Delivery address data',
  fieldset_headline__bemer_partner: 'Your Bemer partner',
  fieldset_headline__wrong_bemer_partner: 'Not your mentor?',
  'option__set-classic': 'Classic Set',
  'option__set-pro': 'Pro Set',
  'option__premium-set-evo': 'Premium Set Evo',
  option__female: 'Female',
  option__male: 'Male',
  option__other: 'Other',
  option__pleaseChoose: 'Please choose...',
  button__submit__label: 'Order now',
  field__organization__label: 'Organization',
  field__taxId__label: 'VAT number',
  field__gender__label: 'Gender',
  field__firstName__label: 'First name',
  field__middleName__label: 'Middle name',
  field__lastName__label: 'Last name',
  field__birthDate__label: 'Date of birth',
  field__birthDateYear__label: 'Year',
  field__birthDateMonth__label: 'Month',
  field__birthDateDay__label: 'Day',
  field__phoneNo__label: 'Phone number',
  field__email__label: 'Your email',
  field__street__label: 'Address',
  field__streetNumber__label: 'Street number',
  field__streetAdditional__label: 'Unit, Suit, ...',
  field__postalCode__label: 'Postal code',
  field__city__label: 'City',
  field__federalState__label: 'Federal State',
  field__distributorNo__label: 'Bemer Id Ihres Bemer Partners',
  field__distributorNameFinder__label: 'Name Ihres Bemer Partners',
  field__country__label: 'Your country',
  button__cancel__label: 'cancel',
  button__switch__label: 'switch',
  button__addDeliveryAddress__label: 'Add a delivery address',
  field__language__label: 'language',
  field__phoneNo__popover:
    "Your phone number will be used to contact you about your order if it's necessary.",
  field_is_optional: '(optional)',
  productPrice_label__total_amount: 'Total:',
  field__distributorName__label: 'Bemer Partner',
  field__distributorName__placeholder: 'Bemer Partner',
};
export const T = (translationKey: string): string =>
  // @ts-ignore
  translations[translationKey];

export const countriesToLanguages = buildCountriesToLanguages([
  {
    language: 'English',
    country: 'United States',
    localeIdentifier: 'en_US' as TLocaleIdentifier,
    currency: 'USD' as TCurrency,
  },
  {
    language: 'Deutsch',
    country: 'Schweiz',
    localeIdentifier: 'de_CH' as TLocaleIdentifier,
    currency: 'CHF' as TCurrency,
  },
  {
    language: 'Français',
    country: 'Schweiz',
    localeIdentifier: 'fr_CH' as TLocaleIdentifier,
    currency: 'CHF' as TCurrency,
  },
  {
    language: 'Deutsch',
    country: 'Deutschland',
    localeIdentifier: 'de_DE' as TLocaleIdentifier,
    currency: 'EUR' as TCurrency,
  },
]);
