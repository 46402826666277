import { TLocaleIdentifier } from '@bemer/types';
import { defaultLocale } from '@bemer/middleware';
import {
  countriesToLanguages,
  T,
} from '../../modules/OrderABemerTrial/storyData.translationData';

export const storyDataDefaultLocaleSelectorData = {
  locale: defaultLocale,
  T,
  countriesToLanguages,
  localeSelectorData: {
    title: 'Are You Sure You Want to Switch the Country?',
    // eslint-disable-next-line
    onSwitch: (newLocale: TLocaleIdentifier) =>
      console.log('newLocale', newLocale),
  },
};
