import {
  checkForPartnerHandleAndPutIntoStore,
  defaultLocale,
} from '@bemer/middleware';
import {
  IGraphqlSiteSettings,
  IGraphqlSupportedLanguage,
  IPropsWebAppPages,
  TLocaleIdentifier,
} from '@bemer/types';
import { BemHeader, BemPage, BemSpinnerWithLogo } from '@bemer/ui-library';
import { storyDataDefaultLocaleSelectorData } from '@bemer/ui-library/src/components/LocaleSelector/storyData.defaultLocaleSelectorData';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import AppHelmet from '../components/Helmet/Helmet';

interface ISanityPage {
  id: string;
  title?: string;
  slug: {
    current: string;
  };
  language: {
    localeIdentifier: TLocaleIdentifier;
  };
}

interface IAllSanityPage {
  edges?: {
    node: ISanityPage;
  }[];
}

interface IAllSanitySiteSettings {
  edges?: {
    node: IGraphqlSiteSettings;
  }[];
}

interface IData {
  allSanityPage: IAllSanityPage;
  allSanitySiteSettings: IAllSanitySiteSettings;
  languages: ICountries;
}

interface ICountries {
  countries: {
    country: IGraphqlSupportedLanguage[];
  }[];
}

interface IPropsHomePage extends IPropsWebAppPages {
  data: IData;
}

const Home = ({ data }: IPropsHomePage): JSX.Element => {
  useEffect(() => {
    // Using a Immediately Invoked Function Expression here to be able to use async await in a useEffect hook.
    (async () => {
      // Determine locale
      console.log(
        '[DETERMINE LOCALE] Use ipstack.com to fetch the users location and set the locale.'
      );
      const url =
        'https://api.ipstack.com/check?access_key=d514807bb88ebbf82dd1d5da137e80bb';

      // Helper to get the localeIdentifier from the ipstack.com response.
      const getLocale = (countryCode: string) => {
        const filteredCountries = data.languages?.countries?.filter(
          (country) =>
            country.country[0].localeIdentifier.split('_')[1] === countryCode
        );
        if (!filteredCountries || filteredCountries.length === 0) {
          return defaultLocale.id;
        }
        const languagesForCountry = filteredCountries[0].country;
        if (languagesForCountry?.length > 0) {
          // check for default lang or use first available language
          return (
            languagesForCountry.filter((lang) => lang.default)[0]
              ?.localeIdentifier || languagesForCountry[0].localeIdentifier
          );
        }
        return languagesForCountry[0].localeIdentifier;
      };

      let localeIdentifier = defaultLocale.id;

      try {
        const response = await fetch(url);
        if (response.ok) {
          const ipData = await response.json();
          console.log(
            `[DETERMINE LOCALE] ipstack.com found countryCode "${ipData.country_code}".`
          );
          localeIdentifier = getLocale(ipData.country_code);
        } else {
          console.warn(
            `[DETERMINE LOCALE] ipstack.com found no countryCode. Response status code is: ${response.status}`
          );
          // Set localeIdentifier to 'en_GB' as ipstack was not successful.
          localeIdentifier = 'en_GB';
        }
      } catch (error) {
        console.error(
          `[DETERMINE LOCALE] Error fetching IP data: ${error.message}`
        );

        localeIdentifier = 'en_GB';
      }

      // Set partner handle to session storage.
      // In case the partnerHandle is not given or the level is not matching the required level,
      // navigate to the error page.
      const partnerHandleSettings = data.allSanitySiteSettings.edges?.find(
        (edge) => edge.node.language.localeIdentifier === localeIdentifier
      )?.node;
      try {
        await checkForPartnerHandleAndPutIntoStore(
          partnerHandleSettings?.minRequiredPartnerLevel || 0
        );
      } catch (error) {
        if (partnerHandleSettings?.isPartnerHandleRequired) {
          navigate(
            partnerHandleSettings?.partnerHandleErrorPage?.slug.current ||
              '/404/'
          );
          return;
        }
      }

      navigate(`/${localeIdentifier}/`);
    })();
  }, []);

  return (
    <BemPage>
      <AppHelmet />
      <BemHeader
        locale={defaultLocale}
        localeSelectorData={
          storyDataDefaultLocaleSelectorData.localeSelectorData
        }
      />
      <BemSpinnerWithLogo rawSx={{ my: '100px' }} />
      <Footer />
    </BemPage>
  );
};

export default Home;

export const query = graphql`
  {
    languages: allSanitySupportedLanguages(
      filter: { active: { eq: true } }
      sort: { fields: country }
    ) {
      countries: group(field: country) {
        country: nodes {
          language
          localeIdentifier
          country
          default
        }
      }
    }

    allSanitySiteSettings {
      edges {
        node {
          language {
            localeIdentifier
          }
          isPartnerHandleRequired
          minRequiredPartnerLevel
          partnerHandleErrorPage {
            slug {
              current
            }
          }
        }
      }
    }

    allSanityPage(sort: { fields: language___localeIdentifier }) {
      edges {
        node {
          id
          title
          slug {
            current
          }

          language {
            localeIdentifier
          }
        }
      }
    }
  }
`;
